<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_ABOUT_US')" type="back" :settings="false" />
    </template>


    <div class="settingsinput-container">
      <div style="width: 100%;height: 100%;position: absolute;top: 0px;z-index: -1;" >
         <img :src="require('@/assets/images/about_us.jpg')" style="width: 100%;height: 99%;" loading="lazy">
      </div>
      <div class="container">
          <div class="about-content">
            <!-- <div class="bg-about">
              <img :src="require('@/assets/images/about_us.jpg')">
            </div> -->
            <div class="logo-about">
                <div class="logo-content">
                  <img :src="require('@/assets/images/logo/logo-name-color.svg')" loading="lazy">
                </div>
                <div class="about-text">
                <p class="main">{{ $t.getTranslation("LBL_ABOUT_PJF") }}</p>
                <p class="sub">{{ $t.getTranslation("LBL_ABOUT_PJF_SUB") }}</p>
                </div>
            </div>
            <div class="about-nav">
              <p class="discover">{{ $t.getTranslation("LBL_DISCOVER") }}</p>
              <p class="explore">{{ $t.getTranslation("LBL_EXPLORE") }}</p>
              <p class="acquire">{{ $t.getTranslation("LBL_ACQUIRE") }}</p>
            </div>
          </div>
        </div>
      </div>



  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted, defineAsyncComponent } from "vue";
import { $HTTP } from "@/utils/axios";
import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { configs, extendedConfigs } from "@/utils/configs.js";
import { useStore } from "@/store";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "ContactUsPage",
  components: {
    ProfileNavigationComponent
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);


    onMounted(async() => {
    });

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };


    return {


    };
  },
});
</script>
<style scoped>

.settingsinput-container .container {
    justify-content: flex-end;
    min-height: calc(100vh - 250px);
}

.bg-about{
  position: absolute;
  top: 0;
}

.bg-about img{
  position: relative;
  height: 812px;
  bottom: -8px;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.logo-about {
    background-color: #16477F;
    color: white;
    width: 300px;
    height: 300px;
    margin: 10px;
    text-align: center;
    line-height: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    position: relative;
    align-items: center;
    align-content: center;
    font-size: 18px;
    padding: 20px 20px 0;
}

.logo-content{
  width: 200px;
  height: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -110px;
}

.logo-content > img{
  width: 200px;
}

.sub{
  line-height: 25px;
}

.about-nav{
  margin-top: 20px;
  display: flex;
  width: 300px;
  justify-content: space-around;
  font-size: 14px;
  color: white;
  z-index: 1;
  font-family:'Times New Roman', Times, serif;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.explore{
  border-left: white 1px solid;
  border-right: white 1px solid;
}

.about-nav p{
  padding: 0 10px;
  height: 15px;
}

.about-text{
  margin-top: 30px
}

@media only screen and (max-width: 500px)  {
  .about-content{
    margin-top: 130px;
  }
  /*.settingsinput-container .container{
    min-height: calc(100vh - 100px) !important;
  }
  p.sub{
    font-size: 14px;
  }
  .logo-about{
    padding: 0px 0px 0px;
  }*/
}



</style>